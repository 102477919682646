import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "locality",
  ];

  initialize() {
    this.locale = $("html").attr("locale");
    this.autocomplete;

    window.initAutocomplete = this.initAutocomplete;

    if (typeof google === 'object' && typeof google.maps === 'object') {
      delete google.maps;
    }
    $.ajax({
      url: `https://maps.googleapis.com/maps/api/js?key=AIzaSyDcNKkuUqj21Z1sTn1dWnNpv0x_yJ8TtNs&libraries=places&language=${this.locale}&callback=initAutocomplete`,
      dataType: "script",
    });
  }

  connect() {}

  initAutocomplete() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById('job_locality'), {types: ['geocode']});

    this.autocomplete.setFields(['address_component']);

    this.autocomplete.addListener('place_changed', function() {
      console.log("hue");
      var place = autocomplete.getPlace();

      var city = place.address_components.find(i => (i.types.includes("locality") || i.types.includes("administrative_area_level_2")));
      var state = place.address_components.find(i => i.types.includes("administrative_area_level_1"));

      $("#job_state").val(state.long_name);
      $("#job_city").val(city.long_name);
    });
  }
}
