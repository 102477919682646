import 'jquery';
import "@stimulus/polyfills";
import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";
import "./welcome.css";
import "../assets/sass/_fonts.scss";
import "../assets/css/all-ie-only.css";
import Rails from "rails-ujs";

const application = Application.start();
const context = require.context("../components/", true, /^\.\/.*\.js$/);

require.context("../assets/images/", true, /\.(gif|jpg|png|svg)$/i);
require.context("../assets/images/logos", true, /\.(gif|jpg|png|svg)$/i);
application.load(definitionsFromContext(context));

window.jQuery = $;
window.$ = $;

$.jMaskGlobals.watchDataMask = true;
