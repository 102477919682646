import { Controller } from "stimulus";
import "./admin.css";

import I18n from 'i18n-js/index.js.erb'
export default class extends Controller {
  static targets = [
    "editBtn",
    "description",
    "category",
    "companyName",
    "states",
    "title",
    "name",
    "userEmail",
    "userRole",
  ];

  initialize() {
    this.locale = $("html").attr("locale");
    this.modal = document.getElementById("modelModal");
    this.span = document.getElementsByClassName("close")[0];
    this.baseUrl = `/${this.locale}/admin`;

    this.addListeners(this.modal);

    this.reportButton = document.getElementById('report-button');
    this.timestamp = '';
    this.interval = '';
  }

  connect() {
    var modal = document.getElementById("modelModal");

    var btn = document.getElementById("modelModalBtn");

    var span = document.getElementsByClassName("close")[0];

    if (btn) {
      btn.onclick = function () {
        modal.style.display = "block";
      };
    }

    span.onclick = function () {
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  }

  newBanner() {
    $.get(`${this.baseUrl}/banners/new`);
  }

  newCompany() {
    $.get(`${this.baseUrl}/companies/new`);
  }

  newInformation() {
    $.get(`${this.baseUrl}/informations/new`);
  }

  newArea() {
    $.get(`${this.baseUrl}/areas/new`);
  }

  editBanner(event) {
    var id = $(event.target).closest("a").data().id;
    var page = $(event.target).closest("a").data().page;

    $.get(`${this.baseUrl}/banners/${id}/edit?page=${page}`);
    this.modal.style.display = "block";
  }

  editCompany() {
    var id = $(event.target).closest("a").data().id;
    var page = $(event.target).closest("a").data().page;

    $.get(`${this.baseUrl}/companies/${id}/edit?page=${page}`);
    this.modal.style.display = "block";
  }

  editInformation() {
    var id = $(event.target).closest("a").data().id;
    var page = $(event.target).closest("a").data().page;

    $.get(`${this.baseUrl}/informations/${id}/edit?page=${page}`);
    this.modal.style.display = "block";
  }

  editContent() {
    var id = $(event.target).closest("a").data().id;
    var editAttr = $(event.target).closest("a").data().editattr;

    $.get(`${this.baseUrl}/contents/${id}/edit?attribute=${editAttr}`);
    this.modal.style.display = "block";
  }

  editUser() {
    var id = $(event.target).closest("a").data().id;
    var page = $(event.target).closest("a").data().page;

    $.get(`${this.baseUrl}/users/${id}/edit?page=${page}`);
    this.modal.style.display = "block";
  }

  editArea() {
    var id = $(event.target).closest("a").data().id;
    var page = $(event.target).closest("a").data().page;

    $.get(`${this.baseUrl}/areas/${id}/edit?page=${page}`);
    this.modal.style.display = "block";
  }

  stateChanged(event) {
    const element = event.target;
    $.get(`/address/cities?state_id=${element.value}`);
  }

  searchBanners() {
    var description = this.descriptionTarget.value;
    var category = this.categoryTarget.value;

    const params = {
      description,
      category
    };

    $.get(`${this.baseUrl}/banners/?${$.param(params)}`);
  }

  searchCompanies() {
    // var companyId = this.companyIdTarget.value;
    var companyName = this.companyNameTarget.value;

    const params = {
      // id: companyId,
      name: companyName
    };

    $.get(`${this.baseUrl}/companies/?${$.param(params)}`);
  }

  searchInformations() {
    var title = this.titleTarget.value;
    const params = { title };

    $.get(`${this.baseUrl}/informations/?${$.param(params)}`);
  }

  searchUsers() {
    var email = this.userEmailTarget.value;
    var role = this.userRoleTarget.value;
    const params = { email, role };

    $.get(`${this.baseUrl}/users/?${$.param(params)}`);
  }

  searchAreas() {
    var name = this.nameTarget.value;
    const params = { name };

    $.get(`${this.baseUrl}/areas/?${$.param(params)}`);
  }

  closeModal() {
    var modal = document.getElementById("modelModal");
    modal.style.display = "none";
  }

  addListeners(modal) {
    this.span.onclick = function () {
      modal.style.display = "none";
    };

    window.onclick = function (event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  }


  /********************
    REPORT JOB FEATURE
  *********************/
  reportJobs() {
    this.createReportJob();
    this.spinnerButton();
    this.reportObservable();
  }

  createReportJob() {
    this.timestamp = Date.now();
    const data = { "job": { "timestamp": this.timestamp } };

    localStorage.setItem('reportActive', 'true');
    localStorage.setItem('reportTimestamp', this.timestamp);

    $.ajax({
      type: 'POST',
      url: '/admin/jobs/report',
      data: data,
      dataType: 'json'
    })
  };

  spinnerButton() {
    if (this.reportButton)
      this.reportButton.innerHTML = `<i class="fas fa-spinner fa-spin"></i> ${I18n.t('labels.main-top.export-loading')}`;
  }

  reportObservable() {
    const reportActive = localStorage.getItem('reportActive');
    const reportTimestamp = localStorage.getItem('reportTimestamp');

    if (reportActive === 'true') {
      this.interval = setInterval(() => {
        $.ajax({
          type: 'GET',
          url: `${location.protocol}//${location.host}/admin/reports/${reportTimestamp}`,
          success: (res) => this.verifyStatuReport(res),
          error: (err) => console.log(err),
        })
      }, 3000);
    }
  }

  verifyStatuReport(res) {
    if (res.status == 'ready') {
      this.stopObservable();
      this.downloadButton();
      this.downloadReport();
    }
  }

  stopObservable() {
    clearInterval(this.interval);
    localStorage.setItem('reportActive', 'false');
  }

  downloadButton() {
    if (this.reportButton)
      this.reportButton.innerHTML = `<i class='fas fa-file-download'></i> ${I18n.t('labels.main-top.export-downloading')}`;
  }

  downloadReport() {
    const reportTimestamp = localStorage.getItem('reportTimestamp');
    const filename = `${reportTimestamp}_report_job.xlsx`;
    const a = document.createElement('a');

    a.href = `${window.location.origin}/reports/${filename}`;
    a.click();
  }

}
