import { Controller } from "stimulus";
import "./jobs_actions.css";

export default class extends Controller {
  static targets = ["title", "states", "cities", "companies", "closed", "locality"];

  initialize() {
    // $.get("/address/states");
    this.locale = $("html").attr("locale");
    this.baseUrl = `/${this.locale}/admin`;
  }

  connect() {
    // Get the modal
    var modal = document.getElementById("modelModal");

    // Get the button that opens the modal
    var btn = document.getElementById("modelModalBtn");

    // Get the <span> element that closes the modal
    var span = document.getElementsByClassName("close")[0];

    // When the user clicks the button, open the modal
    btn.onclick = function() {
      modal.style.display = "block";
    };

    // When the user clicks on <span> (x), close the modal
    span.onclick = function() {
      modal.style.display = "none";
    };

    // When the user clicks anywhere outside of the modal, close it
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    };
  }

  // stateChanged(event) {
  //   const element = event.target;
  //   $.get(`/address/cities?state_id=${element.value}`);
  //
  //   this.searchJobs();
  // }

  // cityChanged(event) {
  //   const element = event.target;
  //   $.get(`/admin/jobs_by_city?city=${element.value}`);
  //
  //   this.searchJobs();
  // }

  // companiesChanged(event) {
  //   const element = event.target;
  //
  //   this.searchJobs();
  // }

  // titleSearch() {
  //   this.searchJobs();
  // }

  searchByLocality(e) {
    this.searchJobs();
  }

  searchJobs() {
    // var state = this.statesTarget.value;
    // var city = this.citiesTarget.value;
    var locality = this.localityTarget.value;
    var company = this.companiesTarget.value;
    var title = this.titleTarget.value;
    var closed = this.closedTarget.checked;
    var id = null;

    if (!isNaN(title)) {
      id = title;
      title = "";
    }

    const params = {
      id: id,
      title: title,
      locality: locality,
      // state_id: state,
      // city_id: city,
      company_id: company,
      closed: closed
    };

    $.get(`${this.baseUrl}/jobs/?${$.param(params)}`);
  }

  new() {
    $.get(`${this.baseUrl}/jobs/new`);
  }

  closeModal() {
    var modal = document.getElementById("modelModal");
    modal.style.display = "none";
  }
}
