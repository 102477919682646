import { Controller } from "stimulus";
import "./carousel.css";

export default class extends Controller {
  static targets = ["watch"];

  initialize() {}

  connect() {

  }

  watchVideo(event) {
    var url = $(event.target).closest("a").data().url;
    var category = $(event.target).closest("a").data().category;
    var description = $(event.target).closest("a").data().description;

    $(".banner-category").html(category);
    $(".banner-description").html(description);

    $("#carouselVideo").attr("src", url);
    $('.modal').addClass('video');
  }
}
