import { Controller } from "stimulus";
import "./job_filter_modal.css";

export default class extends Controller {
  connect() {}

  submitSearchClick() {
    $(".modal").attr("class", "modal");
  }
}
