import { Controller } from "stimulus";
import "./informations.css";

export default class extends Controller {
  static targets = ["title"];

  initialize() {}

  search() {
    this.resetPage();
    const filter = this.titleTarget.value;
    var params = this.searchParams();
    params.title = filter;
    $.get(`/informations?${$.param(params)}`);
  }

  searchParams() {
    if ($(`meta[name="search_params"]`).attr("content")) {
      return JSON.parse($(`meta[name="search_params"]`).attr("content"));
    } else {
      return { page: "1" };
    }
  }

  resetPage() {
    var params = this.searchParams();
    params.page = "1";
    $("meta[name=search_params]").attr("content", JSON.stringify(params));
  }

  nextPage() {
    var params = this.searchParams();
    params.page = (parseInt(params.page) + 1);

    $.get(`/informations_next?${$.param(params)}`);
  }
}
