import { Controller } from "stimulus";
import "./applications.css";

export default class extends Controller {
  static targets = ["editBtn"];

  initialize() {
    this.className = "applications";
    this.modal = document.getElementById("modelModal");
    this.span = document.getElementsByClassName("close")[0];
    this.addListeners(this.modal);
  }

  connect() {
  }

  edit(event) {
    var id = $(event.target).closest("a").data().id;
    var page = $(event.target).closest("a").data().page;

    $.get(`/admin/${this.className}/${id}/edit?page=${page}`);
    this.modal.style.display = "block";
  }

  addListeners(modal) {
    this.span.onclick = function() {
      modal.style.display = "none";
    }
    window.onclick = function(event) {
      if (event.target == modal) {
        modal.style.display = "none";
      }
    }
  }
}
