import { Controller } from "stimulus";
import "./home_page.css";

export default class extends Controller {
  static targets = ["nameQuery"];

  initialize() {
    $('.banner-home').slick({
      autoplay: true,
      autoplaySpeed: 5000,
      arrows: false,
      dots: true,
      infinite: true,
    });

    $('.carousel-opportunities').slick({
      infinite: true,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 750,
          settings: {
            slidesToShow: 1
          }
        }
      ]
    });

    this.addListeners();
  }

  connect() {}

  search() {
    this.resetPage();
    const filter = this.nameQueryTarget.value;
    var params = this.searchParams();
    params.title = filter;

    $.get(`/jobs?${$.param(params)}`);
  }

  nextPage() {
    let windowRelativeBottom = document.documentElement.getBoundingClientRect().bottom;
    if (windowRelativeBottom < document.documentElement.clientHeight + 1) {
      var params = this.searchParams();

      params.page = (parseInt(params.page) + 1);
      $.get(`/jobs_next?${$.param(params)}`);
    }
  }

  getFilters() {

  }

  searchParams() {
    if ($(`meta[name="search_params"]`).attr("content")) {
      return JSON.parse($(`meta[name="search_params"]`).attr("content"));
    } else {
      return {page: "1"}
    }
  }

  addListeners() {
    window.addEventListener('scroll', () => this.nextPage());

    $(window).on('resize orientationchange', function() {
      $('.carousel-opportunities').slick('resize');
    });

    $('.btn--filtros').on('click', function(){
      $('.modal').addClass('filtro');
    });

    // $('.btn--assista').on('click', function(){
    //   $('.modal').addClass('video');
    // });

    $('.modal .fechar').on('click', function(){
      $('.modal').attr('class', 'modal');
    });

    $('#btn-candidatar').on('click', function(){
      $(this).addClass('btn--green');
      $(this).html('Você já se candidatou');
      $('#btn-cancelar').css("display", "flex");
    });

    $('#btn-cancelar').on('click', function(){
      $('#btn-candidatar').removeClass('btn--green');
      $('#btn-candidatar').html('Candidate-se');
      $(this).css("display", "none");
    });
  }

  resetPage() {
    var params = this.searchParams();
    params.page = "1";
    $("meta[name=search_params]").attr("content", JSON.stringify(params));
  }
}
