import { Controller } from "stimulus";
import "./flash.css";

export default class extends Controller {
  static targets = ["flash"];

  initialize() {
    $(this.flashTarget).animate({opacity:'0'}, 5000);
  }

  connect() {}
}
